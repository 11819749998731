import {
    Content,
    InfoCard, Table, TableColumn
} from "@backstage/core-components";
import {Button, Grid, MenuItem} from "@material-ui/core";
import React, {useState} from "react";
import {Entity} from "@backstage/catalog-model";
import useAsync from "react-use/lib/useAsync";
import {BackstageIdentityResponse, ConfigApi, githubAuthApiRef, useApi} from "@backstage/core-plugin-api";
import {CatalogApi, catalogApiRef} from "@backstage/plugin-catalog-react";
import { errorApiRef } from "@backstage/core-plugin-api";
import { configApiRef } from "@backstage/core-plugin-api"
import {IEndpoint} from "../../../backend/src/mongodb/models/endpoint";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TransferList from "./TransferList";
import {CustomerApi, customerApiRef} from "@internal/plugin-customer-plugin/src/api";
import './DarkModeSettings.css'
import Select from "@mui/material/Select";

async function updatePowerLevel(configApi: ConfigApi, profileId: string, powerLevel: number) {
    const headers = { 'Content-Type': 'application/json' }

    const body = JSON.stringify({
        profileId: profileId,
        powerLevel: powerLevel
    })

    const init = {
        method: 'POST',
        body: body,
        headers: headers
    }

    const backend = configApi.get('backend.baseUrl')

    const result = await fetch(backend + '/api/storage/update-powerlevel', init);

    const json = await result.json()

    window.location.reload()

    return json as {[p: string]: ProfileSetting}[]
}

function handleSync(configApi: ConfigApi, dbNames: string[], setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>, setEndpoints:  React.Dispatch<React.SetStateAction<IEndpoint[]>>, setSelectedEndpoints: React.Dispatch<React.SetStateAction<IEndpoint[]>>) {
    loadMissingEntities(configApi, dbNames).then(result => {
        setSelectedEndpoints([])
        setEndpoints(result)
        setDialogOpen(true)
    })
}



async function saveEntity(endpoint: IEndpoint, configApi: ConfigApi, catalogApi: CatalogApi, customerApi: CustomerApi, backstageInfo: BackstageIdentityResponse) {

    const profiles = (await customerApi.loadDashboardProfiles(endpoint._id)).map(v => v.email)

    const headers = { 'Content-Type': 'application/json' }

    const body = JSON.stringify({
        dbName: endpoint.dbName,
        customerName: endpoint.name,
        users: profiles.join(','),
        owner: backstageInfo.identity.userEntityRef.replace("user:default/", "").toLowerCase().replace(" ", ".")
    })

    const init = {
        method: 'POST',
        headers: headers,
        body: body
    }

    const backend = configApi.get('backend.baseUrl')

    const result = await fetch(backend + '/api/storage/save-entity', init);
    const json = await result.json()

    const basePath = json['response'] as string
    const finalURL = basePath + "customers/" + encodeURIComponent(endpoint.name) + "/customer.yaml"

    await catalogApi.addLocation({
        type: 'url',
        target: finalURL
    })

    await catalogApi.addLocation({
        type: 'url',
        target: finalURL,
        dryRun: true,
    })

}

async function handleSave(selectedEndpoints: IEndpoint[], configApi: ConfigApi, catalogApi: CatalogApi, customerApi: CustomerApi, backstageInfo: BackstageIdentityResponse, setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>) {

    for await (const endpoint of selectedEndpoints) {
        await saveEntity(endpoint, configApi, catalogApi, customerApi, backstageInfo)
    }

    setDialogOpen(false)
    window.location.reload()
}

async function loadMissingEntities(configApi: ConfigApi, dbNames: string[]) {
    const headers = { 'Content-Type': 'application/json' }

    const body = JSON.stringify({
        dbNames: dbNames
    })

    const init = {
        method: 'POST',
        headers: headers,
        body: body
    }

    const backend = configApi.get('backend.baseUrl')

    const result = await fetch(backend + '/api/storage/load-missing-entities', init);
    const json = await result.json()

    return json as IEndpoint[]
}

type ProfileSetting = {
    endpointName: string,
    powerLevel: number,
    endpointId: string
}

async function loadProfiles(configApi: ConfigApi): Promise<{ [p: string]: ProfileSetting }[]> {
    const headers = { 'Content-Type': 'application/json' }

    const init = {
        method: 'POST',
        headers: headers
    }

    const backend = configApi.get('backend.baseUrl')

    const result = await fetch(backend + '/api/storage/load-all-profiles', init);

    const json = await result.json()

    return json as {[p: string]: ProfileSetting}[]
}

export function StorageSettings() {

    const apiRef = useApi(catalogApiRef)
    const errorApi = useApi(errorApiRef)
    const configApi = useApi(configApiRef)
    const authApi = useApi(githubAuthApiRef)
    const customerApi = useApi(customerApiRef)


    const [isDialogOpen, setDialogOpen] = useState(false)
    const [endpoints, setEndpoints] = useState([] as IEndpoint[])
    const [selectedEndpoints, setSelectedEndpoints] = useState([] as IEndpoint[])

    const {value, loading, error} = useAsync(async (): Promise<Entity[]> => {
        const result = await apiRef.getEntities({
            filter: {
                'kind': 'Component'
            }
        })
        return result.items
    })

    const {value: profiles, loading: profilesLoading, error: profilesError} = useAsync(async (): Promise<{[p: string]: ProfileSetting}[]> => {
        return loadProfiles(configApi)
    })

    const {value: authenticatedUser, loading: authenticatedLoading, error: authenticatedError} = useAsync(async (): Promise<BackstageIdentityResponse | undefined> => {
        return authApi.getBackstageIdentity()
    })

    if (loading || !value || authenticatedLoading || !authenticatedUser || !profiles || profilesLoading) {
        return <></>
    }

    if (error) {
        errorApi.post(error)
    }

    if (profilesError) {
        errorApi.post(profilesError)
    }

    if (authenticatedError) {
        errorApi.post(authenticatedError)
    }

    const dbNames = value.map(v => v.metadata['dbName'] as string)
    const parsedEmails = profiles.map(x => {
        const key = Object.keys(x)[0]

        return {
            email: key,
            ...x[key]
        }
    })

    const emailColumns: TableColumn[] = [
        {
            title: "Email",
            field: "email"
        },
        {
            title: "Customer",
            field: "endpointName"
        },
        {
            title: 'Power level',
            field: "powerLevel",
            render: (val) => {
                const value = val as ProfileSetting
                return (
                    <Select className={'picker'} value={value.powerLevel} onChange={(target) => updatePowerLevel(configApi, value.endpointId, target.target.value as number)}>
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                    </Select>
                )
            }
        }
    ]

    return (
        <Content>
            <Dialog
                open={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Missing customers"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        I found some customers that you might want to add to voyager.
                        Please, select the ones you are interested in.
                    </DialogContentText>
                    <br/>
                    <TransferList endpoints={endpoints} right={selectedEndpoints} setRight={setSelectedEndpoints}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                    <Button onClick={() => handleSave(selectedEndpoints, configApi, apiRef, customerApi, authenticatedUser, setDialogOpen)} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <InfoCard action={<Button variant={'outlined'} className={'editable_field_button'} onClick={() => handleSync(configApi, dbNames, setDialogOpen, setEndpoints, setSelectedEndpoints)}>Sync</Button>} headerProps={{}} title="Information Card">
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <div>
                                    <p className='editable_field_title'>Number of entities</p>
                                    <p>{dbNames.length}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </InfoCard>
                </Grid>
                <Grid item md={6}>
                    <Table title="Emails" options={{paging: true, pageSize: 5}} columns={emailColumns} data={parsedEmails}/>
                </Grid>
            </Grid>
        </Content>
    )

}
