import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import {ISettings} from "../models/settings";
import {IEndpoint} from "../models/endpoint";
import {IRoute} from "../models/route";
import {Usage} from "../models/usage"
import {ObjectId} from "mongoose";
import {EndpointProfile} from "backend/src/mongodb/models/profile";
import {EditableFields} from "backend/src/mongodb/models/editableFields";
import {ValidatorResult} from "jsonschema"


export const customerApiRef = createApiRef<CustomerApi>({
    id: 'plugin.customerapi.service',
});

export type CustomerEntity = {
    metadata: {users: string}
}

export type EntityFile = {
    name: string
    dbName: string
}

export type CustomerApi = {
    discoveryApi: DiscoveryApi;
    baseUrl: string;
    loadEntityFile(name: string): Promise<EntityFile>
    nameFromDBName(dbName: string): Promise<{ name: string }>
    nameFromEntityFile(name: string): Promise<{ name: string } | null>
    editSettingsActive(endpointId: string, settingName: string, settingSection: string, isActive: boolean): Promise<ISettings>
    editSettingsLabel(endpointId: string, settingName: string, settingSection: string, label: string): Promise<ISettings>
    updateSettings(endpointId: string, settings: ISettings): Promise<ISettings>
    getSettings(endpointId: string): Promise<ISettings | undefined>
    getCustomerEntity(dbName: string): Promise<CustomerEntity>
    getCustomerEndpoint(dbName: string): Promise<IEndpoint>
    getRoutes(dbName: string): Promise<IRoute[]>
    getLastRoutes(dbName: string): Promise<IRoute[]>
    addUserToDashboard(email: String, endpointId: ObjectId): Promise<EndpointProfile[]>
    loadDashboardProfiles(endpointId: string): Promise<EndpointProfile[]>
    loadEndpointUsage(endpointId: string): Promise<Usage[]>
    loadEndpointEditableFields(endpointId: string): Promise<EditableFields>
    editEndpointEditableFields(endpointId: string, editableFields: EditableFields): Promise<IEndpoint>
    downloadSettings(endpointId: string): Promise<JSON>
    uploadSettings(endpointId: string, settingsJSON: JSON): Promise<ValidatorResult>
    saveEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string>
    hideEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string>
    restoreEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string>
    loadMissingEntities(dbNames: string[]): Promise<IEndpoint[]>
    loadCompanyUsage(customersName: string[], pickedCustomer: string): Promise<Usage[]>
    updateCompany(name: string, customers: string[], owner: string): Promise<string>
    removeEmailFromEndpoint(emailIdentifier: string, endpointIdentifier: string): Promise<void>
};
