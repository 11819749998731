import React from "react";

export default function LogoIcon(
    {
        height = 30,
        width = 100,
        color = '#7df3e1',
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="120 0 100.5 102.4" width={width} height={height} fill={color}>
            <path
                d="M49.152,90.129 L51.105,82.443 C51.64,82.441 52.263,82.445 52.852,82.448 L53.024,82.449 L51.034,89.912 L49.152,90.129 Z M0,97.813 L46.499,92.448 L47.94,92.282 L51.94,91.82 L52.616,91.742 L73.46,89.337 L66.385,96.039 L2.849,102.442 L0,97.813 Z M77.155,79.706 C76.429,69.926 73.79,60.651 69.983,52.073 L101.885,0 L125.548,0 L77.155,79.706 Z M67.943,52.411 C72.046,61.523 74.793,71.404 75.279,81.82 C70.042,81.016 63.223,80.408 54.32,80.452 C53.973,80.454 53.475,80.451 52.932,80.448 L52.93,80.448 L52.928,80.448 C52,80.443 50.945,80.438 50.303,80.452 C36.467,80.76 20.045,82.77 4.838,88.331 C12.195,82.43 19.903,73.765 25.108,62.481 C32.272,46.953 34.667,26.527 24.984,1.656 C37.725,10.564 57.34,28.868 67.943,52.411 Z"
                fill="#7df3e1"/>
        </svg>
    )
}