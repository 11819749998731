import {DiscoveryApi} from '@backstage/core-plugin-api';

import {CustomerApi, CustomerEntity, EntityFile} from './CustomerApi';
import {ISettings} from "../models/settings";
import {IEndpoint} from "../models/endpoint";
import {IRoute} from "../models/route";
import {ObjectId} from "mongoose";
import {EndpointProfile} from "backend/src/mongodb/models/profile";
import {EditableFields} from "backend/src/mongodb/models/editableFields";
import {Usage} from "../models/usage";
import {ValidatorResult} from "jsonschema"


export class CustomerApiClient implements CustomerApi {
    discoveryApi: DiscoveryApi;
    baseUrl: string;

    constructor({
                    discoveryApi,
                    baseUrl = 'http://localhost:7007',
                }: {
        discoveryApi: DiscoveryApi;
        baseUrl: string;
    }) {
        this.discoveryApi = discoveryApi;
        this.baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
    }

    async loadEntityFile(name: string): Promise<EntityFile> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            name: name
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }


        const file = await this.fetch<EntityFile | undefined>('api/storage/load-customer-file', init);
        if (file) {
            return file
        }

        throw new Error(`Cannot load entity from name ${name}`)
    }

    async nameFromDBName(dbName: string): Promise<{ name: string }> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            dbName: dbName
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<{ name: string }>('api/storage/load-name-from-dbname', init)
    }
    async nameFromEntityFile(name: string): Promise<{ "name": string } | null> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            name: name
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<{ name: string } | null>('api/storage/load-name-from-entity', init);
    }

    async editSettingsActive(endpointId: string, settingName: string, settingSection: string, isActive: boolean): Promise<ISettings> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
            settingName: settingName,
            settingSection: settingSection,
            isActive: isActive
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<ISettings>('api/storage/edit-setting-active', init);
    }

    async editSettingsLabel(endpointId: string, settingName: string, settingSection: string, label: string): Promise<ISettings> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
            settingName: settingName,
            settingSection: settingSection,
            label: label
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<ISettings>('api/storage/edit-setting-label', init);
    }

    async updateSettings(endpointId: string, settings: ISettings): Promise<ISettings> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
            settings: settings
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<ISettings>('api/storage/update-settings', init);
    }

    async getSettings(endpointId: string): Promise<ISettings | undefined> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<ISettings | undefined>('api/storage/load-settings', init);
    }

    async getCustomerEntity(dbName: String): Promise<CustomerEntity> {
        const headers = { 'Content-Type': 'application/json' }

        const init = {
            method: 'GET',
            headers: headers
        }

        const json = await this.fetch<[{ }]>(`api/catalog/entities?filter=metadata.dbName=${dbName}`, init)
        if (json.length > 0) {
            return json[0] as CustomerEntity
        } else {
            return { metadata: {users: ''}}
        }
    }

    async getCustomerEndpoint(dbName: string): Promise<IEndpoint> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            dbName: dbName
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<IEndpoint>('api/storage/load-customer-endpoint', init);
    }

    async getRoutes(dbName: string): Promise<IRoute[]> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            dbName: dbName
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<IRoute[]>('api/storage/load-routes', init);
    }

    async getLastRoutes(dbName: string): Promise<IRoute[]> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            dbName: dbName
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<IRoute[]>('api/storage/load-last-routes', init);
    }

    addUserToDashboard(email: String, endpointId: ObjectId): Promise<EndpointProfile[]> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            email: email,
            endpoint_id: endpointId
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<EndpointProfile[]>('api/storage/add-dashboard-user', init);
    }

    loadDashboardProfiles(endpointId: string): Promise<EndpointProfile[]> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<EndpointProfile[]>('api/storage/load-dashboard-profiles', init);
    }

    loadEndpointUsage(endpointId: string): Promise<Usage[]> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<Usage[]>('api/storage/load-endpoint-usage', init);
    }

    loadEndpointEditableFields(endpointId: string): Promise<EditableFields> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<EditableFields>('api/storage/load-endpoint-editable-fields', init);
    }

    editEndpointEditableFields(endpointId: string, editableFields: EditableFields): Promise<IEndpoint> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
            editableFields: editableFields
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<IEndpoint>('api/storage/edit-endpoint-editable-fields', init);
    }

    downloadSettings(endpointId: string): Promise<JSON> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<JSON>('api/storage/download-settings', init);
    }

    uploadSettings(endpointId: string, settingsJSON: JSON): Promise<ValidatorResult> {
        const headers = { 'Content-Type': 'application/json' }
        const body = JSON.stringify({
            endpointIdentifier: endpointId,
            settingsJSON: settingsJSON
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<ValidatorResult>('api/storage/upload-settings', init);
    }

    hideEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            dbName: dbName,
            customerName: customerName,
            users: users.join(","),
            owner: owner
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<string>('api/storage/hide-entity', init);
    }

    saveEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            dbName: dbName,
            customerName: customerName,
            users: users.join(","),
            owner: owner
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        return this.fetch<string>('api/storage/save-entity', init);
    }

    restoreEntity(dbName: string, customerName: string, users: string[], owner: string): Promise<string> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            dbName: dbName,
            customerName: customerName,
            users: users.join(","),
            owner: owner
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        console.log('api')
        return this.fetch<string>('api/storage/restore-entity', init);
    }

    loadMissingEntities(dbNames: string[]): Promise<IEndpoint[]> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            dbNames: dbNames
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        console.log('api')
        return this.fetch<IEndpoint[]>('api/storage/load-missing-entities', init);
    }

    loadCompanyUsage(customersName: string[], pickedCustomer: string): Promise<Usage[]> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            customersName: customersName,
            pickedCustomer: pickedCustomer
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        console.log('api')
        return this.fetch<Usage[]>('api/storage/load-company-usage', init);
    }

    updateCompany(name: string, customers: string[], owner: string): Promise<string> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            companyName: name,
            customers: customers,
            owner: owner
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        console.log('api')
        return this.fetch<string>('api/storage/update-company', init);
    }

    removeEmailFromEndpoint(emailIdentifier: string, endpointIdentifier: string): Promise<void> {
        const headers = { 'Content-Type': 'application/json' }

        const body = JSON.stringify({
            emailIdentifier: emailIdentifier,
            endpointIdentifier: endpointIdentifier
        })

        const init = {
            method: 'POST',
            headers: headers,
            body: body
        }

        console.log('api')
        return this.fetch<void>('api/storage/remove-endpoint-email', init);
    }

    private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
        const response = await fetch(`${this.baseUrl}${input}`, init)

        if (!response.ok) throw new Error(response.statusText);
        return response.json();
    }


}
