import {
    createPlugin,
    createRoutableExtension,
    configApiRef,
    discoveryApiRef,
    createApiFactory
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {CustomerApiClient, customerApiRef} from "./api";

export const customerPluginPlugin = createPlugin({
    id: 'customer-plugin',
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: customerApiRef,
            deps: { configApi: configApiRef, discoveryApi: discoveryApiRef },
            factory: ({configApi, discoveryApi}) =>
                new CustomerApiClient({
                    discoveryApi,
                    baseUrl: configApi.getString('backend.baseUrl')
                }),
        }),
    ]
});

export const CustomerPluginPage = customerPluginPlugin.provide(
  createRoutableExtension({
    name: 'CustomerPluginPage',
    component: () =>
      import('./components/CustomerComponent').then(m => m.CustomerComponent),
    mountPoint: rootRouteRef,
  }),
);
