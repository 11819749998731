import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const companyPluginPlugin = createPlugin({
  id: 'company-plugin',
  routes: {
    root: rootRouteRef,
  },
});

export const CompanyPluginPage = companyPluginPlugin.provide(
  createRoutableExtension({
    name: 'CompanyPluginPage',
    component: () =>
      import('./components/CompanyComponent').then(m => m.CompanyComponent),
    mountPoint: rootRouteRef,
  }),
);
